<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <!-- <Button
          label="اضافة جديد"
          icon="pi pi-plus" v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('contractsAdd')"
          @click="$router.push('contracts/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash" v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('contractsDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه تحصيل العقود
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <Column
        field="code"
        header="رقم العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['code']"
            class="p-column-filter"
            placeholder="بحث برقم العقد"
          />
        </template>
      </Column>
      <Column
        field="clientsId.code"
        header="كود العميل"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['clientsId.code']"
            class="p-column-filter"
            placeholder="بحث بكود العميل"
          />
        </template>
      </Column>

      <Column
        field="clientsId.name"
        filterMatchMode="contains"
        header="اسم العميل"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['clientsId.name']"
            class="p-column-filter"
            placeholder="بحث بأسم العميل"
          />
        </template>
      </Column>
      <Column
        field="branchesId.name"
        header="اسم الفرع"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['branchesId.name']"
            class="p-column-filter"
            placeholder="بحث بأسم الفرع"
          />
        </template>
      </Column>

      <!--       
      <Column field="clientsId.name" header="اسم العميل" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="clientsId"
            name="clientsId"
            v-model="filters['clientsId.name']"
            placeholder="بحث باسم العميل"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of clientsList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>
      <Column field="branchesId.name" header="اسم الفرع" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="branchesId"
            name="branchesId"
            v-model="filters['branchesId.name']"
            placeholder="بحث بأسم الفرع"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of branchesList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column> -->

      <Column field="toInstalltions" header="حاله العقد" :sortable="true">
        <template #body="slotProps">
          <span v-if="slotProps.data.toInstalltions == 'new'">
            تحت التحصيل
          </span>
          <span v-if="slotProps.data.toInstalltions == 'in'">
            تحصيل دفعات
          </span>
          <span v-if="slotProps.data.toInstalltions == 'done'">
            تحصيل مكتمل
          </span>
        </template>
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="toInstalltions"
            name="toInstalltions"
            v-model="filters['toInstalltions']"
            placeholder="بحث بأسم الفرع"
          >
            <option :value="null">الكل </option>
            <option value="new">
              تحت التحصيل
            </option>

            <option value="in">
              تحصيل دفعات
            </option>
            <option value="done">
              تحصيل مكتمل
            </option>
          </select>
        </template>
      </Column>
      <Column
        field="date"
        header="تاريخ العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            v-model="filters['date']"
            class="p-column-filter"
            placeholder="بحث بتاريخ العقد"
          />
        </template>
      </Column>
      <Column
        field="total"
        header="قيمه العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['total']"
            class="p-column-filter"
            placeholder="بحث قيمه العقد"
          />
        </template>
      </Column>
      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="usersId.name" header="المندوب" />
            <Column field="deliveryDate" header="اقصى تاريخ تسليم" />
            <Column field="address" header="الموقع" />
            <Column field="note" header="ملاحظات" />
          </DataTable>
          <!-- <DataTable
            :value="slotProps.data.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />
          </DataTable> -->
          <DataTable
            :value="slotProps.data.payments"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه الدفعات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="id" header="الكود">
              <template #body="slotProps2">
                {{ slotProps2.index + 1 }}
              </template>
            </Column>
            <Column field="name" header="الاسم" />
            <Column field="price" header="السعر" />

            <Column
              field="id"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <Button
                  type="button"
                  icon="pi pi-dollar"
                  v-if="
                    $checkRoles('paymentTypeAdd') &&
                      !slotProps2.data.paymentType
                  "
                  @click="
                    openBasic(
                      slotProps2.data.id,
                      slotProps.data.id,
                      slotProps.data.withoutPayment,
                    )
                  "
                  class="p-button-info  p-button-rounded"
                ></Button>
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  v-tooltip="'تعديل'"
                  v-if="
                    $checkRoles('paymentTypeEdit') &&
                      slotProps2.data.paymentType
                  "
                  @click="
                    openBasicEdit(
                      slotProps2.data,
                      slotProps.data.id,
                      slotProps.data.withoutPayment,
                    )
                  "
                  class="p-button-success  p-button-rounded"
                ></Button>
                <!-- <span v-else>
                  تم الاستلام بوسطه {{ slotProps2.data.usersId.name }}
                </span> -->
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>

    <Dialog
      header="الدفع"
      :visible.sync="displayBasic"
      :style="{ width: '80vw' }"
    >
      <div class="p-m-0">
        <div class="form-card row">
          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="date" class="form-label">
                تاريخ المعامله
              </label>
              <input
                class="form-control"
                type="date"
                id="date"
                disabled
                v-model="payment.date"
              />
            </div>
          </div>
          <div class="col-md-4 text-right">
            <div class="mb-3 ">
              <label for="paymentType" class="form-label">
                نوع المعامله
              </label>
              <div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="cach"
                    name="paymentType"
                    value="cach"
                    v-model="payment.paymentType"
                  />
                  <label class="form-check-label" for="cach">كاش</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    id="check"
                    type="radio"
                    name="paymentType"
                    value="check"
                    v-model="payment.paymentType"
                  />
                  <label class="form-check-label" for="check">شيك</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="transfer"
                    name="paymentType"
                    value="transfer"
                    v-model="payment.paymentType"
                  />
                  <label class="form-check-label" for="transfer">تحويل</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="bondNumber" class="form-label required">
                رقم السند
                <span>*</span>
              </label>
              <input
                class="form-control"
                type="number"
                id="bondNumber"
                :class="{ 'is-invalid': isInvalid && !payment.bondNumber }"
                v-model="payment.bondNumber"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="checkNumber" class="form-label">
                رقم الشيك
              </label>
              <input
                :disabled="payment.paymentType != 'check'"
                class="form-control"
                type="number"
                id="checkNumber"
                v-model="payment.checkNumber"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="checkName" class="form-label">
                اسم الشيك
              </label>
              <input
                :disabled="payment.paymentType != 'check'"
                class="form-control"
                id="checkName"
                v-model="payment.checkName"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 ">
              <label for="checkDate" class="form-label">
                تاريخ الشيك
              </label>
              <input
                :disabled="payment.paymentType != 'check'"
                class="form-control"
                type="date"
                id="checkDate"
                v-model="payment.checkDate"
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3 ">
              <label for="note" class="form-label">
                ملاحظات
              </label>
              <textarea
                class="form-control"
                type="note"
                id="note"
                v-model="payment.note"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3 ">
              <label for="files" class="form-label">
                مرفقات
              </label>
              <input
                class="form-control"
                type="file"
                id="files"
                multiple
                @change="previewImage"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="حفظ" icon="pi pi-check" @click="closeBasic" autofocus />
        <Button
          label="الغاء"
          icon="pi pi-times"
          @click="displayBasic = false"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      expandedRows: [],
      loading: true,
      payment: {
        date: `${ye}-${mo}-${da}`,
        paymentType: 'cach',
        bondNumber: null,
        checkNumber: null,
        checkName: null,
        checkDate: null,
        note: null,
        files: [],
      },
      installations: {
        contractsId: null,
        date: null,
        usersId: null,
        materialsId: null,
      },
      paymentId: null,
      filters: {},
      branchesList: [],
      clientsList: [],
      type: null,
      withoutPayment: null,

      isInvalid: false,
    };
  },
  methods: {
    
    async previewImage(ev) {
      if (ev.target.files.length > 0) {
        const list = [];
        for (const item of ev.target.files) {
          const itm = await this.$toBase64(item);
          await list.push(itm);
        }
        this.payment.files = await list;
      } else {
        this.payment.files = [];
      }
      return await true;
    },

    getData() {
      this.$http.get(`contracts/getCollections`).then(
        async (response) => {
          const list = await response.data;
          const newList = [];
          for (let item of list) {
            item.payments = item.payments.sort((a, b) => a.id - b.id);
            newList.push(item);
          }

          this.list = newList;
          let ref = false;
          for (const item of this.list) {
            if (
              !item.payments.find((el) => el.paymentType == null) &&
              item.toInstalltions == 'in'
            ) {
              await this.$http.put(`contracts/addInstalltions/${item.id}`, {
                toInstalltions: 'done',
              });
              ref = await true;
            }
          }
          if (ref) {
            await this.getData();
          }

          this.loading = await false;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    openBasic(id, contractsId, withoutPayment) {
      this.displayBasic = true;
      this.paymentId = id;

      const d = new Date();
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

      this.payment = {
        date: `${ye}-${mo}-${da}`,
        paymentType: 'cach',
        bondNumber: null,
        checkNumber: null,
        checkName: null,
        checkDate: null,
        note: null,
        files: [],
      };

      this.installations.contractsId = contractsId;
      this.withoutPayment = withoutPayment;
    },
    openBasicEdit(data, contractsId, withoutPayment) {
      this.displayBasic = true;
      this.paymentId = data.id;
      this.payment = data;
      this.installations.contractsId = contractsId;
      this.withoutPayment = withoutPayment;
    },
    closeBasic() {
      if (this.payment.bondNumber) {
        this.displayBasic = false;
        this.$http.put(`payments/${this.paymentId}`, this.payment).then(
          () => {
            if (
              this.list.find((el) => el.id == this.installations.contractsId)
                .toInstalltions != 'new' ||
              this.withoutPayment == true
            ) {
              } 
              this.$toast.add({
                severity: 'success',
                summary: 'تم بنجاح',
                detail: 'تم التعديل بنجاح',
                life: 3000,
              });
              this.getData();
            // else {
              // this.toAddInstalltions();
            // }
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`contracts/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`contracts/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    if (!this.$checkRoles('collectionVeiw')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`branches`).then((res) => {
      this.branchesList = res.data;
    });
    this.$http.get(`clients`).then((res) => {
      this.clientsList = res.data;
    });
    this.getData();
  },
};
</script>

<style>
.roles label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
